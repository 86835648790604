<template>
  <div class="info" style="margin-top: 0">
    <top-bar :title="'公告详情'" :left="true"></top-bar>
    <div class="infoBox" :style="{marginTop: this.$topBarShow ? '50px' : '0'}">
      <div class="title">
        <p>{{detailData.title}}</p>
        <div>
          <span>{{detailData.createTime}}</span>
          <span style="float: right; color: #666">{{detailData.readNum || 0}} 人已读</span>
        </div>

      </div>
      <van-divider :hairline="false" />
      <div class="content" v-html="detailData.content">
      </div>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      detailData: {}
    }
  },
  computed: { ...mapState(['noticeId']) },
  components: {
    topBar
  },
  methods: {
    getDetail() {
      console.log(this.noticeId);
      this.$httpCustApp({
        url: this.$httpCustApp.adornUrl('/wxapp/commonApp/notice/info'),
        method: 'post',
        params: this.$httpCustApp.adornParams({
          orgUserId: this.$globalData.userInfo.userId || 0,
          id: this.noticeId || this.$id,
          community: this.$orgId,
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.detailData = data.commonAppNotice
          this.$toast.clear()
        } else {
          console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    }
  },
  created() {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getDetail()
  }
}
</script>
<style>
a {
  color: rgb(4, 50, 255);
}
</style>
<style lang="scss" scoped>
.van-divider {
  border-color: #000;
}

.info {
  .infoBox {
    border-radius: 8px;
    background-color: #fff;
    padding: 30px;

    .title {
      p {
        font-size: 36px;
        margin: 10px 0 20px;
      }

      span {
        font-size: 22px;
        color: #999;
      }
    }

    .content {
      font-size: 30px;
    }
  }
}

</style>
